import { createRouter, createWebHistory } from "vue-router";
import authService from "@/api/services/authService";

// Les composants que tu vas utiliser comme pages
import Home from "@/views/public/Home/HomeView.vue";
import Testeur from "@/views/public/Testeur/IndexTesteur.vue";
import PolitiqueMention from "@/views/public/Home/PolitiqueMention.vue";

// Public
import IndexLogin from "@/views/public/Login/IndexLogin.vue";

// Private
import Dashboard from "@/views/private/Dashboard/DashboardView.vue";
// Product
import GetAllProduct from "@/views/private/Product/GetAllProduct.vue";
import CreateProduct from "@/views/private/Product/CreateProduct.vue";
import DetailProduct from "@/views/private/Product/DetailProduct.vue";
// Sale
import GetAllSale from "@/views/private/Sale/GetAllSale.vue";
import CreateSale from "@/views/private/Sale/CreateSale.vue";
import DetailSale from "@/views/private/Sale/DetailSale.vue";
// Client
import GetAllClient from "@/views/private/Client/GetAllClient.vue";
import DetailClient from "@/views/private/Client/DetailClient.vue";
// Category
import DetailCategory from "@/views/private/Category/DetailCategory.vue";
// Canal
import DetailCanal from "@/views/private/Canal/DetailCanal.vue";
// Rapport
import IndexRapportAi from "@/views/private/Rapport/IndexRapport.vue";
// Setting
import IndexSetting from "@/views/private/Setting/IndexSetting.vue";

import DevTest from "@/views/private/DevTest/DevTest.vue";
import DataRapport from "@/views/private/Rapport/DataRapport.vue";

// Définition des routes
const routes = [
  {
    path: "/",
    name: "Home",
    component: window.matchMedia("(display-mode: standalone)").matches ? IndexLogin : Home,
    meta: { requiresAuth: false, redirectAuth: window.matchMedia("(display-mode: standalone)").matches ? true : false },
  },
  {
    path: "/devtest",
    name: "DevTest",
    component: window.matchMedia("(display-mode: standalone)").matches ? IndexLogin : DevTest,
    meta: { requiresAuth: false, redirectAuth: window.matchMedia("(display-mode: standalone)").matches ? true : false },
  },
  {
    path: "/testeur",
    name: "Testeur",
    component: Testeur,
    meta: { requiresAuth: false, redirectAuth: false },
  },
  {
    path: "/mentions",
    name: "PolitiqueMention",
    component: PolitiqueMention,
    meta: { requiresAuth: false, redirectAuth: false },
  },
  {
    path: "/login",
    name: "IndexLogin",
    component: IndexLogin,
    meta: { requiresAuth: false, redirectAuth: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true, redirectAuth: false },
  },
  {
    path: "/products",
    name: "GetAllProduct",
    component: GetAllProduct,
    meta: { requiresAuth: true, redirectAuth: false },
  },
  {
    path: "/create-product",
    name: "CreateProduct",
    component: CreateProduct,
    meta: { requiresAuth: true, redirectAuth: false },
  },
  {
    path: "/update-product/:id",
    name: "UpdateProduct",
    component: CreateProduct,
    meta: { requiresAuth: true, redirectAuth: false },
  },
  {
    path: "/sale/:id",
    name: "DetailSale",
    component: DetailSale,
    meta: { requiresAuth: true, redirectAuth: false },
  },
  {
    path: "/category/:id",
    name: "DetailCategory",
    component: DetailCategory,
    meta: { requiresAuth: true, redirectAuth: false },
  },
  {
    path: "/canal/:id",
    name: "DetailCanal",
    component: DetailCanal,
    meta: { requiresAuth: true, redirectAuth: false },
  },
  {
    path: "/client/:id",
    name: "DetailClient",
    component: DetailClient,
    meta: { requiresAuth: true, redirectAuth: false },
  },
  {
    path: "/sales",
    name: "GetAllSale",
    component: GetAllSale,
    meta: { requiresAuth: true, redirectAuth: false },
  },
  {
    path: "/product/:id",
    name: "DetailProduct",
    component: DetailProduct,
    meta: { requiresAuth: true, redirectAuth: false },
  },
  {
    path: "/create-sale",
    name: "CreateSale",
    component: CreateSale,
    meta: { requiresAuth: true, redirectAuth: false },
  },
  {
    path: "/clients",
    name: "GetAllClient",
    component: GetAllClient,
    meta: { requiresAuth: true, redirectAuth: false },
  },
  {
    path: "/rapports-data/:type/:date1/:date2/:date3/:date4",
    name: "DataRapportCompare",
    component: DataRapport,
    meta: { requiresAuth: true, redirectAuth: false },
  },
  {
    path: "/rapports-data/:type/:date1/:date2",
    name: "DataRapport",
    component: DataRapport,
    meta: { requiresAuth: true, redirectAuth: false },
  },
  {
    path: "/rapports",
    name: "IndexRapport",
    component: IndexRapportAi,
    meta: { requiresAuth: true, redirectAuth: false },
  },
  {
    path: "/settings",
    name: "IndexSetting",
    component: IndexSetting,
    meta: { requiresAuth: true, redirectAuth: false },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = authService.getToken();

  if (to.meta.requiresAuth && !token) {
    next({ name: "IndexLogin" });
  }
  if (to.meta.redirectAuth && token) {
    next({ name: "Dashboard" });
  } else {
    next();
  }
});

export default router;
