<template>
  <DefaultAppLayout>
    <main class="xl:mr-3 mr-0">
      <div class="max-w-2xl mx-auto lg:max-w-none">
        <div class="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
          <div class="flex flex-col space-y-3">
            <div>
              <h2 class="mb-4 text-lg font-medium text-gray-900">Nom de la ventes</h2>
              <div class="flex flex-col lg:flex-row lg:items-end mt-1 lg:space-x-3 space-y-2">
                <div class="w-full lg:w-1/2">
                  <label for="email-address" class="block text-sm font-medium text-gray-700">Nom ou numéro de
                    facture</label>
                  <div class="mt-2">
                    <input type="text" name="nom" autocomplete="text" v-model="name"
                           class="block w-full border-gray-300 rounded-md shadow-sm focus:border-purple-500 focus:ring-purple-500 text-10"/>
                  </div>
                </div>
                <div class="lg:w-[16rem]">
                  <DefaultSelect label="Canal de vente" :data="salesChannelsData" v-model="salesChannelsDataCurrent"/>
                </div>
                <div class="flex flex-col lg:space-x-3 space-y-2 lg:w-[16rem]">
                  <p class="block text-sm font-medium leading-6 text-gray-900">Date de la vente</p>
                  <vue-tailwind-datepicker
                      i18n="fr"
                      :formatter="{
                          date: 'DD MMM YYYY',
                          month: 'MMM',
                        }"
                      v-model="dateValue"
                      as-single
                  />
                </div>
              </div>
            </div>
            <DefaultBox>
              <h2 class="text-lg font-medium text-gray-900">Ajouter un produit</h2>
              <div v-if="productBuy == null" class="py-6 mt-4">
                <button @click="onCloseModalProduct(true)"
                        class="w-full py-3 text-base font-medium text-white border border-transparent rounded-full shadow-sm bg-slate-800 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-50">
                  Ajouter
                  de produit
                </button>
              </div>
            </DefaultBox>
            <div class="w-full overflow-scroll">
              <DefaultProductCard v-if="productBuy != null" :product="productBuy"/>
            </div>
            <DefaultBox>
              <div v-if="productBuy != null" class="pt-5">
                <h2 class="text-lg font-medium text-gray-900">Information de la ventes</h2>
                <div class="flex space-x-3">
                  <DefaultSelectPrice label="Prix du produit" :data="productBuy.prices" v-model="selectedDataPrice"/>
                  <div class="lg:w-[30rem]">
                    <DefaultSelect label="Client du produit" :data="clientsData" v-model="clientsDataCurrent"/>
                  </div>
                </div>
              </div>
              <div v-if="productBuy != null" class="flex flex-col py-6 space-y-3">
                <button @click="onCloseModalProduct(true)"
                        class="w-full py-3 text-base font-medium bg-transparent border rounded-full shadow-sm text-slate-800 border-slate-800 hover:bg-slate-100">
                  Changer
                  le produit
                </button>
                <button @click="addProductsBuyFormatPost"
                        class="w-full py-3 text-base font-medium text-white border border-transparent rounded-full shadow-sm bg-slate-800 hover:bg-slate-700">
                  Ajouter
                  de produit
                </button>
              </div>
            </DefaultBox>
          </div>
          <div class="mt-10 lg:mt-0">
            <h2 class="text-lg font-medium text-gray-900">Votre vente</h2>
            <ProductCheckout
                :submit="postDataCreateSale"
                :products="productsBuySelected"
                :realProduct="productsBuyRealSelected"
                :deleteProduct="deleteProduct"
                :addMultipleProduct="addMultipleProduct"
                :salesChannelsDataCurrent="salesChannelsDataCurrent"
                :productsBuyFormatPost="productsBuyFormatPost"
                :name="name"
                :date="dateValue[0]"
            />
          </div>
        </div>
      </div>
    </main>
    <ProductModal
        :open="openModalProduct"
        @close="onCloseModalProduct"
        :selectProductAction="addProductBuy"
        v-if="products.length !== 0"
        :data="products"/>
  </DefaultAppLayout>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import {toast} from "vue-sonner";

import DefaultAppLayout from '@/components/Layout/DefaultAppLayout.vue'
import DefaultSelect from '@/components/Select/DefaultSelect.vue';
import DefaultSelectPrice from '@/components/Select/DefaultSelectPrice.vue';
import ProductCheckout from '@/views/private/Sale/components/ProductCheckout.vue';
import DefaultBox from '@/components/Layout/DefaultBox.vue';
import DefaultProductCard from '@/components/Other/DefaultProductCard.vue';
import ProductModal from '@/components/Modal/ProductModal.vue';
import {GetAllProductData} from "@/api/services/ProductServiceFunctions";
import {GetAllClientData} from "@/api/services/ClientServiceFunctions";
import {GetAllSalesChannelData} from "@/api/services/SalesChannelServiceFunctions";
import VueTailwindDatepicker from "vue-tailwind-datepicker";

const name = ref("");
const products = ref([]);
const clientsData = ref([]);
const clientsDataCurrent = ref([]);
const salesChannelsData = ref([]);
const salesChannelsDataCurrent = ref([]);
const productBuy = ref(null);
const selectedDataPrice = ref({})
const productsBuySelected = ref([]);
const productsBuyRealSelected = ref([]);
const productsBuyFormatPost = ref([]);
const openModalProduct = ref(false)
const dateValue = ref([new Date().toLocaleDateString('fr-FR', {day: 'numeric', month: 'short', year: 'numeric'})]);

const addProductsBuyFormatPost = () => {
  if (selectedDataPrice.value['@id'] && productBuy.value['@id'] && clientsDataCurrent.value['@id']) {
    const uniqueId = `${productBuy.value['@id']}_${selectedDataPrice.value['@id']}`;

    const existingProductIndex = productsBuySelected.value.findIndex(
        (item) => item.uniqueId === uniqueId
    );

    if (existingProductIndex === -1) {
      let dataFormated = {
        ...productBuy.value,
        price: selectedDataPrice.value,
        client: clientsDataCurrent.value,
        uniqueId
      };

      productsBuySelected.value.push(dataFormated);
      productsBuyRealSelected.value.push(dataFormated);
      productsBuyFormatPost.value.push({
        "product": productBuy.value['@id'],
        "price": selectedDataPrice.value['@id'],
        "client": clientsDataCurrent.value['@id'],
      });
    } else {
      toast.error("Ce produit avec ce prix est déjà ajouté.");
    }

    productBuy.value = null;
    selectedDataPrice.value = null;
  } else {
    toast.error("Vous devez ajouter toutes les données demandées.");
  }
};

const addMultipleProduct = (event, product) => {
  productsBuyFormatPost.value = productsBuyFormatPost.value.filter((item) => item.product !== product['@id'])
  productsBuyRealSelected.value = productsBuyRealSelected.value.filter((item) => item.id !== product.id)
  for (let i = 0; i < event.target.value; i++) {
    productsBuyFormatPost.value.push({
      "product": product['@id'],
      "price": product.price['@id'],
      "client": product.client['@id'],
    })
    productsBuyRealSelected.value.push(product)
  }
}

const deleteProduct = (product) => {
  productsBuySelected.value = productsBuySelected.value.filter((item) => {
    return item.price.id !== product.price.id;
  });

  productsBuyRealSelected.value = productsBuyRealSelected.value.filter((item) => {
    return item.price.id !== product.price.id;
  });
};

const addProductBuy = (product) => {
  productBuy.value = product
  openModalProduct.value = false
}

const onCloseModalProduct = (value) => {
  openModalProduct.value = value
}

const fetchDatas = async () => {
  products.value = await GetAllProductData();
  clientsData.value = await GetAllClientData();
  salesChannelsData.value = await GetAllSalesChannelData();
};

onMounted(() => {
  fetchDatas();
});
</script>